<template>
<div>
    <linkbutton toplabel="Get New Batch" @click="Add()"/>
    <!-- ACCT NOTE MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form>
                <label class="titlelabel">Get New Batch</label>
                
                <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Filter by Location</label>
                    </div>
                    <select name="" id="" class='entrySelect' v-model="person.locationId" @change="selectLocation()">
                        <option v-for="location in locations" :key="location.id" :value="location.id" >
                            {{location.name}}
                        </option>
                    </select>
                </div>
                <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Filter by Role</label>
                    </div>
                    <select name="" id="" class='entrySelect' v-model="person.personRoleId">
                        <option v-for="role in roles" :key="role.id" :value="role.id" >
                            {{role.name}}
                        </option>
                    </select>
                </div>
                

                <formentry    label="Filter by Name or Badge #"  v-model="person.badgeNumber" />

                <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Record Count</label>
                    </div>
                    <select name="" id="" class='entrySelect' >
                        <option v-for="reccount in reccounts" :key="reccount.name" :value="reccount.name" >
                            {{reccount.name}}
                        </option>
                    </select>
                </div>
                

    
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Go" />
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                <div>
                    <label class="responsemessage">{{message}}</label>
               </div>
          </form>
        </template>
      </actionmodal>
    </div>
</template>

<script>
import datamethods from '../../datamethods.js'
import smallbutton from '../controls/button.vue'
import actionmodal from '../controls/actionmodal.vue'
import formentry from '../controls/entry.vue'
import linkbutton from '../controls/linkbutton.vue'
export default {
    name:'selectbatch',
    components:{
        smallbutton,
        actionmodal,
        formentry,
        linkbutton
    },
    props:{

        
    },
    mixins:[datamethods],
    data(){
        return{
            person:{},
            message:'',
            roles:[],
            locations:[],
            classes:[],
            tmpresp:{},
            reccounts:[
                {name:'25'},
                {name:'50'},
                {name:'100'}
            ]
        }
    },
    methods:{
        async Add(){
            this.person={
                locationId:null,
                emailAddress:null,
                password:null,

                firstName:null,
                lastName:null,
                phoneNumber:null,
                personRoleId:null,
                personRoleIdClass:null,
                badgeNumber:null,
            }
            this.$refs.mymodal.openModal()
        },
        
        async saveNew(){
            
            let req = this.person
            
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'badgesetup')
            if(response.Successful){
                console.log(response)
                if(response.id<0){
                    this.message=response.message
                }else{
                    this.message="Congrats! You added a badge."
                }
                
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },

        async getRoles(){
            //setup request
            let req = {
                locationId:this.person.locationId
            }
            //call api
            var response = await this.callAPI(req,'rolelist')
            //handle response
            if(response.Successful){
                this.roles=response.PersonRoleList
            }else{
                this.message = response.Message
            }
        },
       
        async getLocations(){
            
            //call api
            var response = await this.callAPI(null,'locationlist')
            //handle response
            this.tmpresp = response
            if(response.Successful){
                this.locations=response.institutionLocationList
            }else{
                this.message = response.Message
            }
        },
        selectLocation(){
            this.getRoles();
        }
    },
    mounted(){
       
        this.getLocations();
        
    }
}
</script>

<style>
.entryCheckLabel{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
}
.entryCheckLabelbold{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
    font-weight:bold;
}
</style>





























